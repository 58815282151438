<template>
  <main>
    <div class="flex items-center w-full justify-between text-gray-600">
      <input
        type="text"
        :value="value"
        @input="$emit('input', $event.target.value)"
        class="w-full  py-2   rounded-md leading-7 text-sm border input-focus border-gray-300 px-3"
        placeholder="Search account name"
      />
    </div>
  </main>
</template>

<script>
export default {
  props: {
    value: {},
  },
};
</script>

<style></style>
