<template>
  <main>
    <div data-test="export-csv-button" @click="downloadCsv"
      class="border py-3 flex group items-center px-5   cursor-pointer border-primaryBlue text-xs rounded-md text-primaryBlue font-medium">
      <svg v-if="loading" class="spinner mr-4 w-4 h-4 text-primaryBlue stroke-current stroke-2 " viewBox="0 0 50 50">
        <circle class="path text-primaryBlue" stroke="#253B95" cx="25" cy="25" r="20" fill="none" stroke-width="5">
        </circle>
      </svg>
      <svg v-else class="w-4 h-4  ml-4  mr-4" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.7237 9.04736V11.6826C15.7237 12.4099 15.0264 13.0002 14.1672 13.0002H3.27139C2.41218 13.0002 1.71484 12.4099 1.71484 11.6826L1.71484 9.04736"
          stroke="#253B95" stroke-width="1.464" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M12.6108 4.43609L8.71948 1.14209L4.82812 4.43609" stroke="#253B95" stroke-width="1.464"
          stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M8.71726 1.14209V9.04769" stroke="#253B95" stroke-width="1.464" stroke-linecap="round"
          stroke-linejoin="round"></path>
      </svg>
      Export Csv
    </div>
  </main>
</template>

<script>
import { eventBus } from "../../eventBus.js";
import moment from "moment";
// import { isEqual } from 'lodash';
export default {
  props: ["selectedStores", "date"],
  data() {
    return {
      loading: false,
      moment,
    };
  },
  computed: {
    formatedDate() {
      return {
        startDate: this.changeDateFormatToYYYY_MM_DD(this.date.startDate),
        endDate: this.changeDateFormatToYYYY_MM_DD(this.date.endDate),
      }
    }
  },
  methods: {
    changeDateFormatToYYYY_MM_DD(timestamp = 0) {
      return moment(timestamp).format('YYYY-MM-DD');
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      // link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadCsv() {
      // if ((this.allPayment || []).length === 0) {
      //   eventBus.$emit("trac-alert", {
      //     message: "Payments list is empty.",
      //   });
      //   return;
      // }

      const payload = {
        startDate: this.formatedDate.startDate,
        endDate: this.formatedDate.endDate,
        storeIds: {
          list_of_store_ids: this.selectedStores.map((stores) => stores.id),
        },
      };
      this.loading = true;
      const res = await this.$store.dispatch("GET_PAYMENT_CSV", payload);
      if (res.status) {
        this.downloadURI(res.data);
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "There is no data available.",
        });
      }
      this.loading = false;
    },
    // async downloadCsv() {
    //   const payload = {
    //     startDate: this.date.startDate,
    //     endDate: this.date.endDate,
    //     storeIds: {
    //       list_of_store_ids: this.selectedStores.map((stores) => stores.id),
    //     },
    //   };
    //   this.loading = true;
    //   const res = await this.$store.dispatch("GET_JSON_PAYMENT", payload);
    //   if (res.data.items === null) {
    //     eventBus.$emit("trac-alert", {
    //       message: res.data.items || "There is no data available.",
    //     });
    //   } else {
    //     let csvData = res.data.items;
    //     let formattedData = csvData.map((log) => {
    //       return {
    //         "Business Email": log.business_email,
    //         "Business Name": log.business_name,

    //         "Created At": this.moment(log.created_at).format(
    //           "DD MMM YYYY - h:mm:ss a"
    //         ),
    //         "Payment Method": log.payment_method,
    //         "Transaction Type": log.transaction_type,
    //         "Transaction ID": log.transaction_id,
    //         "Payment Receipt": log.payment_receipt,
    //         "Receipt Url": log.receipt_url,
    //         "Store Name": log.store_name,
    //         "Sale Agent": log.sales_agent,
    //         "Bank Name": log.bank_name,
    //         "Account Name": log.account_name,
    //         "Card Amount Paid": log.card_amount_paid,
    //         "Transaction Fee": log.transaction_fee,
    //         "Loan Fee": log.loan_fee,
    //         "Net fee due": log.net_fee_due_merchant,
    //         "Customer Name": log.customer_name,
    //         "Account Number": log.account_number,
    //         "CARD PAN": log.recipient_details.payment_details.card_number,
    //         "CARD TYPE": log.recipient_details.payment_details.card_type,

    //         TID: log.recipient_details.terminal_details.terminal_id,
    //       };
    //     });
    //     const convertCSV = this.arrayToCSV(formattedData);
    //     var element = document.createElement("a");
    //     element.href = "data:text/csv;charset=utf-8," + encodeURI(convertCSV);
    //     element.target = "_blank";
    //     element.download = "Payments.csv";
    //     element.click();
    //   }
    //   this.loading = false;
    // },

    // arrayToCSV(objArray) {
    //   const array =
    //     typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    //   let str =
    //     `${Object.keys(array[0])
    //       .map((value) => `"${value}"`)
    //       .join(",")}` + "\r\n";

    //   return array.reduce((str, next) => {
    //     str +=
    //       `${Object.values(next)
    //         .map((value) => `"${value}"`)
    //         .join(",")}` + "\r\n";
    //     return str;
    //   }, str);
    // },
  },
};
</script>

<style>
</style>
