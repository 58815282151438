<template>
  <trac-modal
    v-if="value"
    @close="$emit('input', !value)"
    top="pt-10"
    size="max-w-3xl"
    class="w-full"
  >
    <div class="pt-10 md:px-16">
      <h1 class="text-xs font-medium">Details</h1>
      <div class="mt-5 pb-6 flex justify-between">
        <div>
          <h1 class="text-xs text-accentDark">Amount</h1>
          <h2 class="text-base tracking-wide font-medium py-1 text-accentDark">
            {{ modalObject.amount_paid | formatPrice }}
          </h2>
          <div
            class="mt-1 inline-block text-xs font-medium rounded-l-full rounded-r-full"
            :class="[
              {
                'bg-green-300  text-green-500 px-2':
                  modalObject.payment_status == 'Received',
              },
              {
                'bg-green-300  text-green-500 px-2':
                  modalObject.payment_status == 'paid',
              },
              {
                'bg-yellow-200 text-yellow-500 px-2':
                  modalObject.payment_status == 'pending',
              },
              {
                'bg-red-200 text-red-500 px-2':
                  modalObject.payment_status == 'failed',
              },
            ]"
          >
            {{ modalObject.payment_status }}
          </div>
        </div>
        <h2 class="text-primaryBlue font-medium text-xs"></h2>
      </div>
      <div class="mt-5">
        <h1 class="text-xs text-primaryGray">Customer</h1>
        <h2 class="text-primaryBlue text-xs mt-1 underline">
          {{ modalObject.customer_name }}
        </h2>
        <div class="mt-5 grid grid-cols-2 items-center">
          <div>
            <h1 class="text-xs text-primaryGray">Date</h1>
            <h2 class="text-accentDark text-xs mt-1">
              {{
                moment(modalObject.date_of_payment_by_customer).format(
                  'Do-MMM-YYYY'
                )
              }}
            </h2>
          </div>
          <div>
            <h1 class="text-xs text-primaryGray">Reference</h1>
            <h2 class="text-accentDark text-xs mt-1">
              {{ modalObject.payment_reference_code }}
            </h2>
          </div>
        </div>
      </div>
      <div class="mt-6 -mx-5 table-shadow rounded-md px-4 py-5">
        <div
          v-if="
            modalObject.payment_method == 'bank_transfer' ||
            modalObject.payment_method == 'fixed_account'
          "
        >
          <h1 class="text-sm font-medium uppercase">PAYMENT Details</h1>
          <div class="grid grid-cols-2 mt-4 row-gap-6">
            <div>
              <h1 class="text-xs text-primaryGray">Payment Method</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{ bankNameType(modalObject.payment_method) }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Source Bank</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{
                  modalObject.payment_log_details.bank_payment_details.bank_name
                }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Account Name</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{
                  modalObject.payment_log_details.bank_payment_details
                    .account_name
                }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Account Number</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{
                  modalObject.payment_log_details.bank_payment_details
                    .account_number
                }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Payment receipt</h1>
              <h2
                @click="openReceipt = !openReceipt"
                class="text-primaryBlue cursor-pointer underline text-xs mt-1"
              >
                {{ !openReceipt ? 'View receipt' : 'Hide Receipt' }}
              </h2>
              <span v-if="openReceipt" class="text-xs"
                ><a
                  class="text-primaryBlue"
                  target="_blank"
                  :href="
                    modalObject.payment_log_details.payment_details.receipt_url
                  "
                >
                  {{
                    modalObject.payment_log_details.payment_details
                      .payment_receipt
                  }}: Visit Link</a
                ></span
              >
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Destination Bank</h1>
              <h2 class="text-accentDark text-xs mt-1 break-words">
                {{ modalObject.payment_channel || 'N/A' }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Pos Payment Details</h1>
              <div class="text-accentDark text-xs mt-1">
                RRN:
                {{
                  modalObject.payment_log_details.pos_payment_details.rrn ||
                  'na'
                }}
              </div>
              <div class="text-accentDark text-xs mt-1">
                STAN:
                {{
                  modalObject.payment_log_details.pos_payment_details.stan ||
                  'na'
                }}
              </div>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Narration</h1>
              <h2 class="text-accentDark text-xs mt-1 break-words">
                {{
                  modalObject.payment_log_details.transaction_remark || 'N/A'
                }}
              </h2>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 class="text-sm font-medium uppercase">PAYMENT Details</h1>
          <div class="grid grid-cols-2 mt-4 row-gap-6">
            <div>
              <h1 class="text-xs text-primaryGray">Payment Method</h1>
              <h2 class="text-accentDark text-xs mt-1 uppercase">
                {{ modalObject.payment_method }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Card Type</h1>
              <h2 class="text-accentDark box text-xs mt-1">
                {{ modalObject.payment_log_details.payment_details.card_type }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Card number</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{
                  modalObject.payment_log_details.payment_details.card_number
                }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Payment receipt</h1>
              <h2
                @click="openReceipt = !openReceipt"
                class="text-primaryBlue cursor-pointer underline text-xs mt-1"
              >
                {{ !openReceipt ? 'View receipt' : 'Hide Receipt' }}
              </h2>
              <span v-if="openReceipt" class="text-xs"
                ><a
                  class="text-primaryBlue"
                  target="_blank"
                  :href="
                    modalObject.payment_log_details.payment_details.receipt_url
                  "
                >
                  {{
                    modalObject.payment_log_details.payment_details
                      .payment_receipt
                  }}: Visit Link</a
                ></span
              >
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Pos Payment Details</h1>
              <div class="text-accentDark text-xs mt-1">
                RRN:
                {{
                  modalObject.payment_log_details.pos_payment_details.rrn ||
                  'na'
                }}
              </div>
              <div class="text-accentDark text-xs mt-1">
                STAN:
                {{
                  modalObject.payment_log_details.pos_payment_details.stan ||
                  'na'
                }}
              </div>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Terminal Id</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{
                  modalObject.payment_log_details.terminal_details
                    .terminal_id || 'N/A'
                }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 -mx-5 table-shadow rounded-md px-4 py-5 mb-10">
        <div class="flex items-center justify-between">
          <h1 class="text-sm font-medium uppercase">Sales Details</h1>
          <h1
            class="tiny font-medium uppercase text-primaryBlue items-center flex"
          >
            View SALES RECORD
            <svg
              class="ml-2"
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 11.5L6.4 6.1L1 0.700001"
                stroke="#003283"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </h1>
        </div>
        <div class="grid grid-cols-2 mt-4 row-gap-6">
          <div>
            <h1 class="text-xs text-primaryGray">Sales ID</h1>
            <h2 class="text-accentDark text-xs mt-1">
              {{ modalObject.payment_log_details.sales_details.sales_id }}
            </h2>
          </div>
          <div>
            <h1 class="text-xs text-primaryGray">Sold By</h1>
            <h2 class="text-accentDark text-xs mt-1">
              {{ modalObject.payment_log_details.sales_details.sold_by }}
            </h2>
          </div>
          <div>
            <h1 class="text-xs text-primaryGray">Store Name</h1>
            <h2 class="text-accentDark text-xs mt-1">
              {{ modalObject.payment_log_details.sales_details.store_name }}
            </h2>
          </div>
          <div>
            <h1 class="text-xs text-primaryGray">Customer Name</h1>
            <h2 class="text-xs mt-1">
              {{
                modalObject.payment_log_details.customer_details.customer_name
              }}
            </h2>
          </div>
        </div>
      </div>

      <div class="mt-6 -mx-5 table-shadow rounded-md px-4 py-5 mb-10">
        <div class="flex items-center justify-between">
          <h1 class="text-sm font-medium uppercase">Sales Agents</h1>
        </div>
        <div class="grid grid-cols-2 mt-4 row-gap-6">
          <div>
            <h1 class="text-xs text-primaryGray">Cashier ID</h1>
            <h2 class="text-accentDark text-xs mt-1">
              {{
                modalObject.payment_log_details.sales_agent.cashier_id || 'N/A'
              }}
            </h2>
          </div>
          <div>
            <h1 class="text-xs text-primaryGray">Cashier Name</h1>
            <h2 class="text-accentDark text-xs mt-1">
              {{ modalObject.payment_log_details.sales_agent.name || 'N/A' }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </trac-modal>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      openReceipt: false,
    };
  },
  props: ["modalObject", "value"],

  methods: {
    bankNameType(account) {
      if (account == "bank_transfer") {
        return "Bank Transfer (Dynamic)";
      } else if (account == "fixed_account") {
        return "Bank Transfer (Fixed)";
      } else {
        return account;
      }
    },
  },
};
</script>

<style></style>
