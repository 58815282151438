import { GET_USER_DATA } from "../browser-db-config/localStorage";

const filterOnlyAssigned = (
    fetchedArray = [],
    fieldName = 'id'
) => {
    const assignedArray = (GET_USER_DATA() || {}).stores || []
    const filtered = []
    console.log('Stores Assigned to user:', assignedArray)

    assignedArray.forEach((record) => {
        const assignedRecord = record.id
        const foundInFetched = (fetchedArray.find(item => item[fieldName] === assignedRecord) || {})
        console.log(
            `searching ${assignedRecord} === ${foundInFetched[fieldName]}: ${assignedRecord === foundInFetched[fieldName]}`
        );
        if (foundInFetched && foundInFetched[fieldName]) {
            filtered.push(foundInFetched)
        }
    })
    return filtered;
};

export { filterOnlyAssigned }