<template>
  <div>
    <trac-dual-sync v-if="isACleanSlate" :status="'syncing'" :progress="progress" @onResync="reSync"></trac-dual-sync>
    <!-- Normal Loading UI -->
    <div v-else>
      <trac-loading v-if="searchingStatus" />
      <h1 class="mt-5 font-semibold text-lg">Payments</h1>
      <div class="flex justify-between">
        <div class="mt-8 w-full flex flex-col max-w-4xl">
          <PaymentInfo :currentName="currentName" :selectedStores="selectedStores" :date="date" :sum="ADVtotalPayment"
            :isCalculating="calculatingTotal" :totalizerProgress="totalizerProgress">
          </PaymentInfo>
          <div class="flex items-center gap-x-2 mt-10 z-20">
            <PaymentSearch v-model="search" data-test="transaction-search-field" class="flex-1" />
            <DateFilter data-test="clear-filter-button" :preSelected="currentName" @labelchange="updateLabel"
              @updated="updateDate" />
            <Stores data-test="transaction-select-store-dropdown" @toggleCheck="toggleCheck" :stores="storeData"
              :checkStores="checkStores" v-model="selectedStores" />

            <StatusFilter @currentStatus="selectedStatus = $event"  @agentsStatus="agentsStatus = $event" data-test="status-filter" />
            <button @click="doCleanSearch()" :disabled="searchingStatus" class="
                px-4
                py-3
                text-xs
                rounded-md
                text-white
                button-style
                font-semibold
              ">
              <span v-if="searchingStatus === false">Search</span>
              <span v-else>Searching...</span>
            </button>
            <!-- <div class="flex items-center justify-between gap-5 z-20">
              <button class="px-4 py-3 mt-3 text-xs rounded-md text-white button-style font-semibold">Search</button>
            </div> -->
          </div>
          <div v-if="showSearchSummary" class="
              flex
              bg-blue-100
              p-3
              rounded-md
              items-center
              gap-x-2
              my-2
              text-xs
            ">
            <div v-if="searchResultCount > 0" class="font-bold">
              {{ searchResultCount }} Results for:
            </div>
            <div v-if="search" class="search-query">"{{ search }}"</div>
            <div data-test="data-filter-dropdown" v-if="currentName !== 'Date Filter' && currentName"
              class="search-query">
              Date Range: {{ currentName }}
            </div>
            <div v-if="agentsStatus.length" class="search-query">
            Sales Agents:
            <span
              class="capitalize"
              v-for="(agents, index) in agentsStatus"
              :key="agents.id"
            >
              {{ agents.first_name }} {{ agents.last_name }}
              <span v-if="index < agentsStatus.length - 1">, </span>
            </span>
          </div>
            <div v-else class="search-query">
              {{ moment(date.startDate).format("DD-MMMM-yyyy") }} -
              {{ moment(date.endDate).format("DD-MMMM-yyyy") }}
            </div>
            <div class="search-query">{{ selectedStores.length }} Stores</div>
            <div class="flex-1"></div>
            <div @click="clearFilters()" class="cursor-pointer border border-gray-600 px-2 rounded-md py-1">
              Clear Filters
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-between">
          <div class="mt-8" v-if="getUserStatus &&
            permissionsModules[2].parent.permissionsForUser
              .viewAndManagePayouts
            ">
            <ViewPayout />
          </div>
          <div v-if="isOnline" class="justify-end items-end flex">
            <DownloadCsv :selectedStores="selectedStores" :date="date" />
          </div>
        </div>
      </div>

      <PaymentTable class="mt-12 z-50" :tableData="payments" :length="totalRecords" @getMoreData="fetchData" />
    </div>
  </div>
</template>

<script>
import AdvancedSearch from "../../offline-module/advancedSearch";
import { cleanSlate } from "../../offline-module/offline.store";
import { filterOnlyAssigned } from "../../offline-module/storeFilter";
import moment from "moment";
import PaymentInfo from "./PaymentInfo.vue";
import PaymentSearch from "./PaymentSearch.vue";
import DateFilter from "./DateFilter_v2.vue";
import Stores from "./SelectedStores.vue";
import PaymentTable from "./PaymentTable.vue";
import DownloadCsv from "./DownloadCsv";
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
} from "../../browser-db-config/localStorage";
import { permissionsModules } from "./../settings/permission";
import ViewPayout from "./ViewPayout.vue";
import StatusFilter from "./StatusFilter.vue";
import OfflineManager from "../../offline-module";
import { eventBus } from "../../eventBus";

export default {
  data() {
    return {
      calculationControl: new AbortController(),
      calculatingTotal: false,
      permissionsModules,
      showSearchSummary: false,
      ADVtotalPayment: 0,
      moment,
      storeData: [],
      selectedStores: [],
      selectedStatus: [],
      currentName: "Today",
      payments: [],
      pageNumber: 0,
      currentlyShowingPayments: [],
      check: true,
      loading: false,
      search: "",
      progress: {},
      totalRecords: 0,
      searchingStatus: false,
      date: {
        startDate: moment().startOf("day").valueOf(),
        endDate: moment().endOf("day").valueOf(),
      },
      adv: {
        totalizerProgress: {
          total: 0,
          done: 0
        }
      },
      agentsStatus:[]
    };
  },

  components: {
    PaymentInfo,
    PaymentSearch,
    DateFilter,
    Stores,
    PaymentTable,
    DownloadCsv,
    ViewPayout,
    StatusFilter,
  },

  watch: {
    date(value) {
      // console.log("startDate watch", value);
      if (this.isOnline) {
        // debugger
        // await this.doFilter();
        // this.$forceUpdate()
        // await this.getPayments();
      } else {
      }
    },
    SWData(newValue, oldValue) {
      console.log("Payments -> SWData, ", newValue); // For debugging/checking this fires in console.log
      if (this.isADownloadOnlySyncEvent(newValue, "payments")) {
        // this.dualSyncStatus = false;
        cleanSlate.moduleList["payments"] = false;
        // const BID = GET_USER_BUSINESS_ID() || null
        // if(BID) {
        //   console.log('re-initiating offlineManager')
        //   this.$InstantiateGlobalOfflineManager(BID)
        // }
        // this.resetFilters();
        // this.loadAllRecordsFromOffline();
        // write function to load all offline records
      }
      if (this.isAProgressSyncEvent(newValue, "payments")) {
        this.progress = newValue.data;
      }
    },
  },

  computed: {
    hasDoneASearch() {
      return this.showSearchSummary;
    },
    onScreenTotal() {
      return (this.payments || []).reduce(
        (prev, next) => prev + next.amount_paid,
        0
      );
    },
    totalizerProgress() {
      return this.adv.totalizerProgress;
    },
    isACleanSlate() {
      return cleanSlate.moduleList["payments"] === true;
    },
    getUserStatus() {
      let usersStatus = GET_LOCAL_DB_DATA("traction-app-user-data");
      if (usersStatus.user.role === "admin") {
        return true;
      } else {
        return false;
      }
    },
    percentageSearched() {
      return `${Math.floor(
        (this.adv.currentIndex / this.adv.numberOfPages) * 100
      )}%`;
    },
    checkStores() {
      return this.selectedStores.length === this.storeData.length;
    },
    searchResultCount() {
      return 0;
    },
    paymentsCount() {
      return (this.payments || []).length;
    },
    formatPayments() {
      let payments = this.payments || [];

      let formattedStores = this.selectedStores.map((stores) => stores.id);

      if (this.selectedStores.length) {
        payments = payments.filter((payment) =>
          formattedStores.includes(payment.store_id.toLowerCase())
        );
      } else {
        payments = [];
      }

      if (this.selectedStatus.length) {
        payments = payments.filter((payment) =>
          this.selectedStatus.includes(payment.payment_status.toLowerCase())
        );
      }

      if (this.search !== "") {
        payments = payments.filter(
          (payment) =>
            payment.payment_log_details.bank_payment_details.account_name
              .toLowerCase()
              .includes(this.search.trim().toLowerCase()) ||
            payment.amount_paid
              .toString()
              .includes(this.search.trim().toLowerCase()) ||
            payment.payment_reference
              .toString()
              .includes(this.search.trim().toLowerCase())
        );
      }
      return payments;
    },

    totalPayment() {
      return this.formatPayments.reduce((a, b) => a + b.amount_paid, 0);
    },

    paginatedPayments() {
      return this.formatPayments.slice(0, 30 * this.pageNumber);
    },
  },
  beforeDestroy() {
    this.calculationControl.abort();
    // console.log('stopped any totalization ongoing')
  },
  methods: {
    async clearFilters() {
      this.resetFilters();
      const isOnline = await this.NetworkCheck();
      if (isOnline) {
        this.doCleanSearch();
      } else {
        this.loadAllRecordsFromOffline();
      }
    },
    async doCleanSearch() {
      this.adv.currentIndex = 0;
      this.payments = [];
      this.showSearchSummary = true;
      this.calculationControl.abort();
      // this.adv.reset();
      const isOnline = await this.NetworkCheck();
      // debugger
      if (isOnline) {
        // const isTodayOrYesterday = this.currentName === "Today" || this.currentName === "Yesterday"
        // // debugger
        // if(isTodayOrYesterday) {
        //   this.searchOnlyTodayAndYesterday()
        //   this.searchOnline({calculateTotal: false});
        // } else {
        //   this.searchOnline({calculateTotal: true});
        // }
        this.searchOnline({calculateTotal: true});

      } else {
        this.searchOffline();
      }
    },
    allStoresWereSelected() {
      return this.storeData.length === this.selectedStores.length
    },
    searchOnlyTodayAndYesterday() {
      this.$store
        .dispatch("FETCH_TRANSACTIONS").then((results) => {
          if (results.status) {
            let total = 0
            if (this.currentName === "Today") {
              total = results.data.today.transaction.transaction_total || 0
            }
            if (this.currentName === "Yesterday") {
              total = results.data.yesterday.transaction.transaction_total || 0
            }
            this.ADVtotalPayment = total
          }
        })
        .catch((err) => {
          this.error = err;
          this.loading = false;
          console.error(err);
        })
    },
    searchOnline(options = { calculateTotal: false }) {
      let agentsId
      const dateLabel = this.currentName
      // get start and end date
      const dateRange = {
        startDate: moment(this.date.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.date.endDate).format("YYYY-MM-DD"),
      };

      this.searchingStatus = true;
      const formattedStoresString = this.selectedStores
        .map((record) => record.id)
        .join(",");

      let searchPayload = {
        dateRange,
        storeIds: formattedStoresString  
      } 

      if (this.agentsStatus.length) {
          agentsId = this.agentsStatus.map((record) => record.sales_agent_code).join(",");
          searchPayload.agentsId = agentsId;
      }     

      this.$store
        .dispatch("FETCH_ALL_PAYMENTS_V2", searchPayload)
        .then((results) => {
          // debugger
          let fetched = results.data.item.list_of_payments || [];
          this.payments = fetched;
          this.ADVtotalPayment = results.data.item.total_value_payments || 0;
          // debugger
          this.searchingStatus = false;
        })
        .catch((err) => {
          console.error(err);
          eventBus.$emit("trac-alert", {
            message: `Network Error`,
          });
          this.searchingStatus = false;
        });
    },
    filterResults(
      records = [],
      selectedStores = [],
      account_name = null,
      selectedStatus = []
    ) {
      const containsStore = (storeID) => {
        const storeMatch = selectedStores.includes(storeID);
        console.log("Store Match?", storeMatch);
        return storeMatch;
      };

      const hasStatus = (status) => {
        const statusMatch = selectedStatus.includes(status.toLowerCase());
        return statusMatch;
      };

      const hasName = (record, textSearch = null) => {
        const account_name = (
          ((record || {}).payment_log_details || {}).bank_payment_details || {}
        ).account_name;
        if (
          account_name &&
          typeof account_name === "string" &&
          textSearch &&
          typeof textSearch === "string"
        ) {
          return account_name.toLowerCase().search(textSearch) > -1;
        } else {
          return false;
        }
      };

      let total = 0;
      let filtered = [];
      records.forEach((record) => {
        const hasStore = containsStore(record.store_id);
        const matchesAccountName = hasName(record, account_name);
        const recordHasStatus = hasStatus(record.payment_status);
        const searchCriteria = account_name
          ? hasStore && matchesAccountName && recordHasStatus
          : hasStore && recordHasStatus;
        // debugger;
        if (searchCriteria) {
          total += record.amount_paid;
          filtered.push(record);
        }
      });
      return {
        total,
        filtered,
      };
    },
    searchOffline() {
      this.doFilter()
        .then((searchCriteria) => {
          this.calculatingTotal = true;
          this.resetAbortController();
          return this.calculateTotalFromSearch(searchCriteria);
        })
        .then((totalAmount) => {
          // in the event the next sync fires this.resetFilters(),
          // this.hasDoneASearch till be false, and so we shouldn't
          // show a total for a search criteria that has been cleared
          this.ADVtotalPayment = this.hasDoneASearch ? totalAmount : 0;
          this.calculatingTotal = false;
        })
        .catch((err) => {
          this.ADVtotalPayment = 0;
          this.calculatingTotal = false;
        });
    },
    calculateTotalFromSearch(where = {}) {
      return new Promise((resolve, reject) => {
        this.calculationControl.signal.addEventListener("abort", () => {
          console.warn("totalization has been cancelled");
          reject(new DOMException("Aborted", "AbortError"));
        });

        this.adv
          .totalizer({
            tableName: "payments",
            searchCriteria: where,
            recordProperty: "amount_paid",
            abortSignal: this.calculationControl.signal,
          })
          .then((totalAmount) => {
            resolve(totalAmount);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetFilters() {
      const rightNow = Date.now() * 1000;
      this.calculationControl.abort();
      this.currentName = "today";
      this.search = "";
      this.date = {
        startDate: moment(rightNow).startOf("day").unix(),
        endDate: moment(rightNow).endOf("day").unix(),
      };
      this.pageNumber = 0;
      this.showSearchSummary = false;
      this.ADVtotalPayment = 0;
      // this.adv.reset();
      const resetAbortController = setTimeout(() => {
        this.resetAbortController();
        clearTimeout(resetAbortController);
      }, 1000);
    },
    resetAbortController() {
      this.calculationControl = new AbortController();
    },
    updateLabel(label) {
      this.currentName = label;
    },
    updateDate(dateRange) {
      this.date = dateRange;
      console.log("updated date range: ", dateRange);
      // debugger
      // this.doFilter()
    },
    reSync(data) {
      console.log("resync function: ", data);
      // For single request
      if (this.isOnline) {
        this.resetDualSyncDialog();
        this.requestSync("payments", {
          params: { start: this.date.startDate, end: this.date.endDate },
        });
      } else {
        this.resetDualSyncDialog();
        this.loadAllRecordsFromOffline();
      }
    },
    async doFilter() {
      this.searchingStatus = true;
      this.showSearchSummary = true;
      const where = {};
      const filterObject = {
        searchWord: this.search,
        dateRange: {
          startDate: this.date.startDate,
          endDate: this.date.endDate,
        },
        selectedStores: this.selectedStores,
        statuses: this.selectedStatus,
      };

      // get all selected stores and add to where object
      const storeIDs = filterObject.selectedStores.map((store) => store.id);
      const statuses = filterObject.statuses;

      // prepare our criteria object
      where.store_id = ["CONTAINS", storeIDs];
      where.payment_status = ["CONTAINS", statuses];
      where.created_at = [
        "RANGE",
        {
          start: filterObject.dateRange.startDate,
          end: filterObject.dateRange.endDate,
        },
        "timestamp",
      ];

      if (filterObject.searchWord) {
        where.payment_log_details = [
          "CONTAINS",
          filterObject.searchWord,
          "nested",
          ["bank_payment_details", "account_name"],
        ];
      }

      const results = await this.adv.doSearch({
        tableName: "payments",
        searchCriteria: where,
        pageIndex: this.pageNumber,
        resultsPerSearch: 100,
        loopThrough: false,
      });
      this.payments = [].concat(this.payments, results);
      this.searchingStatus = false;
      return where;
      // debugger;
    },
    async loadAllRecordsFromOffline() {
      this.totalRecords = await this.adv.getTotalRecords("payments");
      await this.fetchAllStores();
      const results = await this.adv.paginateRecords({
        tableName: "payments",
        pageIndex: 0,
        loopThrough: false,
        resultsPerSearch: 30, // <-- first set must be at least 30 for checkScroll() to work
      });

      // const results = await this.$GlobalOfflineManager.getAll('payments')

      console.log("Records from payments: ", results);

      // debugger

      this.payments = results;
    },

    async fetchData() {
      const isOnline = await this.NetworkCheck();
      // if a search word exists, paginate against search
      if (isOnline) {
        // do nothing
      } else {
        // do offline pagination
        if (this.hasDoneASearch) {
          if (this.pageNumber < this.adv.numberOfPages) {
            this.pageNumber += 1;
            this.doFilter();
          }
        } else {
          // paginate against all records
          if (this.pageNumber < this.adv.numberOfPages) {
            this.pageNumber += 1;
            // add paginate fxn here
            this.adv
              .paginateRecords({
                tableName: "payments",
                pageIndex: this.pageNumber,
                loopThrough: false,
                resultsPerSearch: 30,
              })
              .then((fetched) => {
                this.payments = [].concat(this.payments, fetched);
                // debugger
              });
            // debugger
          }
        }
      }
    },

    toggleCheck(value) {
      // debugger
      if (value) {
        this.selectedStores = this.storeData;
      } else {
        this.selectedStores = [];
      }
      this.$forceUpdate();
    },

    update(value) {
      this.currentName = value;
    },

    async fetchAllStores() {
      // await this.$store.dispatch("FETCH_ALL_STORES");
      // let res = this.$store.getters["GET_ALL_STORES"];
      const allStores = await this.$GlobalOfflineManager.getAll("stores");
      const assignedStores = filterOnlyAssigned(allStores, "_id") || [];
      let res = (GET_USER_DATA() || {}).stores || [];
      let formattedStores = res.map((store) => {
        return {
          name: store.name,
          id: store.id,
        };
      });
      this.storeData = formattedStores;
      this.selectedStores = formattedStores;
    },

    async getPayments() {
      this.loading = true;
      let res = await this.$store.dispatch("FETCH_ALL_PAYMENTS", this.date);
      if (res.status) {
        this.payments = res.data.items.list_of_payments;
        this.loading = false;
      }
      return res;
    },
  },

  async created() {
    // this.totalRecords = await this.adv.getTotalRecords("payments");
    await this.NetworkCheck();
    await this.fetchAllStores();
    this.dualSyncStatus = "syncing";
    // this.loadAllRecordsFromOffline();

    // In these context, we do initiate a search upon entering
    // to make sure we search against records records
    this.doCleanSearch();
  },
};
</script>
<style lang="postcss" scoped>
.search-query {
  @apply bg-blue-700 text-white px-2 rounded-md py-1;
}

.button-style {
  background: linear-gradient(318.39deg, #0143aa 8.55%, #003283 90.68%);
}
</style>